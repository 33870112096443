// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Gradients
$enable-gradients: true;


/*------------------------------------
- COLOR robrand
------------------------------------*/

$robrand_t1: #2b3047;
$robrand_t2: #171a27;
$robrand_t3: #6a75a5;
$robrand_t4: #7a84ae;
$robrand_t5: #000000;
$robrand_t6: #11141d;
$robrand_t7: #1d2030;
$robrand_t8: #000000;
$robrand_t9: #878fb6;

$robrand_shadow: rgba(43, 48, 71, 0.5);
$robrand_text_color_dark_bright: #fff;


.alert-robrand {
    color: $robrand_t5;
    background-color: $robrand_t9;
    border-color: $robrand_t4;
}

.alert-robrand hr {
    border-top-color: $robrand_t3;
}

.alert-robrand .alert-link {
    color: $robrand_t8;
}

.badge-robrand {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t1;
}

.badge-robrand[href]:hover, .badge-robrand[href]:focus {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t2;
}

.bg-robrand {
    background-color: $robrand_t1 !important;
}

a.bg-robrand:hover, a.bg-robrand:focus,
button.bg-robrand:hover,
button.bg-robrand:focus {
    background-color: $robrand_t2 !important;
}

.border-robrand {
    border-color: $robrand_t1 !important;
}

.btn-robrand {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t1;
    border-color: $robrand_t1;
}

.btn-robrand:hover {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t7;
    border-color: $robrand_t2;
}

.btn-robrand:focus, .btn-robrand.focus {
    box-shadow: 0 0 0 0.2rem $robrand_shadow;
}

.btn-robrand.disabled, .btn-robrand:disabled {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t1;
    border-color: $robrand_t1;
}

.btn-robrand:not(:disabled):not(.disabled):active, .btn-robrand:not(:disabled):not(.disabled).active, .show > .btn-robrand.dropdown-toggle {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t2;
    border-color: $robrand_t6;
}

.btn-robrand:not(:disabled):not(.disabled):active:focus, .btn-robrand:not(:disabled):not(.disabled).active:focus, .show > .btn-robrand.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem $robrand_shadow;
}

.btn-outline-robrand {
    color: $robrand_t1;
    background-color: transparent;
    border-color: $robrand_t1;
}

.btn-outline-robrand:hover {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t1;
    border-color: $robrand_t1;
}

.btn-outline-robrand:focus, .btn-outline-robrand.focus {
    box-shadow: 0 0 0 0.2rem $robrand_shadow;
}

.btn-outline-robrand.disabled, .btn-outline-robrand:disabled {
    color: $robrand_t1;
    background-color: transparent;
}

.btn-outline-robrand:not(:disabled):not(.disabled):active, .btn-outline-robrand:not(:disabled):not(.disabled).active, .show > .btn-outline-robrand.dropdown-toggle {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t1;
    border-color: $robrand_t1;
}

.btn-outline-robrand:not(:disabled):not(.disabled):active:focus, .btn-outline-robrand:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-robrand.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem $robrand_shadow;
}

.list-group-item-robrand {
    color: $robrand_t5;
    background-color: $robrand_t4;
}

.list-group-item-robrand.list-group-item-action:hover, .list-group-item-robrand.list-group-item-action:focus {
    color: $robrand_t5;
    background-color: $robrand_t3;
}

.list-group-item-robrand.list-group-item-action.active {
    color: $robrand_text_color_dark_bright;
    background-color: $robrand_t5;
    border-color: $robrand_t5;
}

.table-robrand,
.table-robrand > th,
.table-robrand > td {
    background-color: $robrand_t4;
}

.table-hover .table-robrand:hover {
    background-color: $robrand_t3;
}

.table-hover .table-robrand:hover > td,
.table-hover .table-robrand:hover > th {
    background-color: $robrand_t3;
}

.text-robrand {
    color: $robrand_t1 !important;
}

.text-robrand-dark {
    color: $robrand_t2 !important;
}

a.text-robrand:hover, a.text-robrand:focus {
    color: $robrand_t2 !important;
}


.bg-robrand .dropdown-item:hover,
.bg-robrand .dropdown-item:focus {
    color: $robrand_text_color_dark_bright;
    text-decoration: none;
    background: $robrand_t2 linear-gradient(180deg, $robrand_t7, $robrand_t2) repeat-x !important;
}

.bg-robrand .dropdown-item.active,
.bg-robrand .dropdown-item:active {
    color: $robrand_text_color_dark_bright;
    text-decoration: none;
    background: $robrand_t3 linear-gradient(180deg, $robrand_t4, $robrand_t3) repeat-x !important;
}

.bg-robrand .dropdown-item.disabled,
.bg-robrand .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
    background-image: none;
}
