// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import url("https://netdna.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.css");
